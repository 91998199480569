import * as This from '@radix-ui/react-tooltip'
import { c, FC, PropsOf } from 'lib/component-utils'
import { useAtomValue } from 'jotai'
import { browserInfoAtom } from 'lib/atoms'
import { ReactElement } from 'react'

type Props = {
  children?: ReactElement | undefined
  className?: string
  content: JSX.Element | string | number
  sizing?: 'default' | 'text' | 'custom'
  disabled?: boolean
  disableHoverableContent?: boolean
  rootProps?: PropsOf<typeof This.Root>
}

const sizingClass = {
  default: 'px-2 py-1.5 text-xs font-medium text-gray-700 rounded-md',
  text: 'px-3 py-2 rounded-lg',
  custom: '',
} as const

const Tooltip: FC<Props, typeof This.Content> = ({
  className,
  children,
  content,
  sizing = 'default',
  disabled,
  disableHoverableContent = false,
  rootProps,
  ...rest
}) => {
  const os = useAtomValue(browserInfoAtom)?.os
  const childType = children?.type
  const childIsLink = childType === 'a' || (childType as any)?.render?.name === 'LinkComponent'

  /* iOS made a decision to apply :hover state on first press which makes tapping links that have tooltips
  extra annoying. This removes tooltips from links on iOS only. */
  return (os === 'iOS' && childIsLink) || disabled ? (
    <>{children}</>
  ) : (
    <This.Root disableHoverableContent={disableHoverableContent} {...rootProps}>
      <This.Trigger asChild>{children}</This.Trigger>
      <This.Portal>
        <This.Content
          collisionPadding={12}
          sideOffset={10}
          className={c`z-[9999] max-w-prose bg-white/70 backdrop-blur-xl backdrop-saturate-200 shadow-lg ring-1 ring-black/5 ${sizingClass[sizing]} ${className}`}
          {...rest}
        >
          {content}
        </This.Content>
      </This.Portal>
    </This.Root>
  )
}

export default Tooltip
