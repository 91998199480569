import { c, FC } from 'lib/component-utils'
import { forwardRef } from 'react'

const Card: FC = ({ className, ...rest }, ref) => {
  return (
    <div
      className={c`bg-white rounded-lg shadow-sm ring-1 ring-gray-200 overflow-hidden ${className}`}
      ref={ref}
      {...rest}
    />
  )
}

export default forwardRef(Card as any) as typeof Card
