import { useAtomValue, useSetAtom } from 'jotai'
import { Notification as NotificationType, notificationsAtom } from 'lib/atoms'
import { FC } from 'lib/component-utils'
import { AnimatePresence } from 'framer-motion'
import Notification from 'components/base/Notification'
import { WritableDraft } from 'immer/dist/internal'
import { useCallback } from 'react'

export type ToastType = 'success' | 'info' | 'warning' | 'error'

let id = 0

export const useToast = () => {
  const updateNotificationState = useSetAtom(notificationsAtom)

  const sendToast = useCallback(
    (type: ToastType, title: string, message?: string) =>
      updateNotificationState((s) => {
        const currentId = id++
        s.push({ id: currentId, type, title, message })
        setTimeout(() => updateNotificationState((s) => s.filter((e) => e.id !== currentId)), 3000)
      }),
    [updateNotificationState]
  )

  return sendToast
}

export const useNotification = () => {
  const updateNotificationState = useSetAtom(notificationsAtom)

  const sendNotification = useCallback(
    (notification: Omit<NotificationType, 'id'>) =>
      updateNotificationState((s) => {
        const currentId = id++
        s.push({ id: currentId, ...notification } as WritableDraft<NotificationType>)
        setTimeout(() => updateNotificationState((s) => s.filter((e) => e.id !== currentId)), 8000)
      }),
    [updateNotificationState]
  )

  return sendNotification
}

const NotificationArea: FC = () => {
  const notifications = useAtomValue(notificationsAtom)

  return (
    <div
      aria-live="assertive"
      className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-[9999]"
    >
      <div className="flex flex-col-reverse items-center w-full space-y-4 space-y-reverse sm:items-end">
        <AnimatePresence>
          {notifications.map((notification) => (
            <Notification key={notification.id} notification={notification} />
          ))}
        </AnimatePresence>
      </div>
    </div>
  )
}

export default NotificationArea
