import useTranslation from 'next-translate/useTranslation'
import { ISO8601DateString } from 'lib/dto'
import { useUserContext } from 'lib/context'
import parsePhoneNumber from 'libphonenumber-js'
import { match } from 'ts-pattern'

type NullPropagatingFormatter<TValue, TOptions> = <TNullable extends TValue | null | undefined>(
  value: TNullable,
  options?: TOptions
) => TNullable extends null | undefined ? string | null : string

export class Formatter {
  constructor(readonly lang: string, readonly currency: string) {}

  country = (code: string, options?: Omit<Intl.DisplayNamesOptions, 'type'>) =>
    code !== 'все' && 'DisplayNames' in Intl
      ? new Intl.DisplayNames(this.lang, { type: 'region', ...options }).of(code)
      : code

  date: NullPropagatingFormatter<Date | ISO8601DateString, Intl.DateTimeFormatOptions> = (
    date,
    options
  ) =>
    date != null
      ? new Intl.DateTimeFormat(this.lang, options).format(new Date(date))
      : (null as any)

  number: NullPropagatingFormatter<number, Intl.NumberFormatOptions> = (number, options) =>
    number != null && !Number.isNaN(number) && Number.isFinite(number)
      ? number.toLocaleString(this.lang, options)
      : (null as any)

  phone: NullPropagatingFormatter<string, never> = (phone) =>
    phone != null ? parsePhoneNumber(phone)?.formatInternational() : (null as any)

  price: NullPropagatingFormatter<number | bigint, Intl.NumberFormatOptions> = (price, options) =>
    price != null && !Number.isNaN(price) && Number.isFinite(price)
      ? (options?.currency ?? this.currency) !== 'UZS'
        ? new Intl.NumberFormat(this.lang, {
            style: 'currency',
            currency: this.currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: this.currency === 'RUB' ? 0 : 2,
            ...options,
          }).format(price)
        : (['ru', 'uz'].includes(this.lang) ? '' : 'UZS\xa0') +
          new Intl.NumberFormat(this.lang === 'uz' ? 'ru' : this.lang, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            ...options,
          }).format(price) +
          match(this.lang)
            .with('ru', () => '\xa0сум')
            .with('uz', () => "\xa0so'm")
            .otherwise(() => '')
      : (null as any)

  priceRangeToParts = (start: number | null, end: number | null) => {
    const showStart = start !== null
    const showEnd = showStart && end !== null && start !== end

    if (!showStart) return [null, null, null] as const

    const formattedStartPrice =
      showEnd && this.lang === 'ru'
        ? this.number(start, {
            maximumFractionDigits: this.currency === 'RUB' ? 0 : 2,
          })
        : this.price(start)

    if (!showEnd) return [formattedStartPrice, null, null] as const

    const separator = '–'
    const formattedEndPrice =
      this.lang === 'en'
        ? this.number(end, {
            maximumFractionDigits: this.currency === 'RUB' ? 0 : 2,
          })
        : this.price(end)

    return [formattedStartPrice, separator, formattedEndPrice] as const
  }

  priceRange = (start: number | null, end: number | null) => {
    return this.priceRangeToParts(start, end).filter(Boolean).join('') || null
  }
}

export const useIntl = () => {
  const { currentMall } = useUserContext()
  const { t, lang } = useTranslation('common')

  return {
    t,
    lang: lang as 'ru' | 'en' | 'zh' | 'uz',
    format: new Formatter(lang, currentMall.currency),
  }
}
