import { detect } from 'detect-browser'
import { atom } from 'jotai'
import { atomWithImmer } from 'jotai-immer'
import { HTMLProps } from 'react'

type FloatingElementState = {
  shown: boolean
  contents: JSX.Element | null
}

export type NotificationType = 'success' | 'info' | 'warning' | 'error'

type Action = {
  title: string
  action: () => void
}
export type Notification = {
  id: number
  type: NotificationType
  title: string
  message?: string
  primaryLink?: { title: string; props: HTMLProps<HTMLAnchorElement> }
  primaryButton?: Action
  secondaryButton?: Action
}

export const currentModalAtom = atomWithImmer<FloatingElementState>({
  shown: false,
  contents: null,
})
export const notificationsAtom = atomWithImmer<Notification[]>([])

export const browserInfoAtom = atom(() => detect())
